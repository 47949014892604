export const HEALTH_STATUS = [
  'Mắt',
  'Nhiễm',
  'Da liễu',
  'Đau đầu',
  'Thần kinh',
  'Huyết học',
  'Tai Mũi Họng',
  'Răng hàm mặt',
  'Ung thư - Ung bướu',
  'Tâm lý',
  'Hô hấp',
  'Nội tiết',
  'Tiêu hóa',
  'Tim mạch',
  'Thận - Tiết niệu',
  'Cơ - xương khớp',
  'Sức khỏe sinh sản',
  'Miễn dịch - Dị ứng',
]
