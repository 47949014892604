export const CATEGORIES = [
  {
    title: 'MEDterm',
    description: 'Từ điển y khoa',
  },
  {
    title: '5 phút biết tuốt',
    description: '5 phút tìm hiểu chi tiết về một vấn đề',
  },
  {
    title: 'Bắt mạch',
    description: 'Giải đáp thắc mắc liên quan đến các bệnh lý',
  },
  {
    title: 'Kê toa',
    description: 'Trình bày việc sử dụng một số loại thuốc trong điều trị bệnh',
  },
  {
    title: 'Đỉnh của chóp',
    description: 'Review trải nghiệm điều trị',
  },
  {
    title: 'Chém myth',
    description: 'Làm rõ tính đúng sai về các tin đồn đoán',
  },
  {
    title: 'Hỏi nhỏ đáp to',
    description: 'Giải đáp thắc mắc liên quan đến các bệnh lý',
  },

  {
    title: 'Ai? Ở đâu? Khi nào?',
    description: 'Câu chuyện của các nhân vật trong ngành y học Việt Nam',
  },
  {
    title: 'Biết sao không?',
    description: 'Giải thích các triệu chứng bệnh và nguyên tắc chấn đoán',
  },
  {
    title: 'Người trong ngành',
    description: 'Giải thích các triệu chứng bệnh và nguyên tắc chấn đoán',
  },
]

export const TOPIC = [
  'Thuốc',
  'Covid-19',
  'Sức khỏe tinh thần',
  'Sống khỏe chủ động',
]

export const emptyTopicDescription = (topicName: string) => `
Bạn biết không? Nguyên team 40 con người nhà VHL đang ngày đêm suy nghĩ, tìm tài liệu viết nhiều bài chất lượng về chủ đề ${topicName} này. 
<br /><br />
Cùng chờ đón bài viết từ VHL nhé!
`
