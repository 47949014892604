export const CATEGORIES_COLORS = [
  {
    color: '#FF813A',
    categories: [
      'Đau đầu',
      'Mắt',
      'Răng hàm mặt',
      'Tai Mũi Họng',
      'Thần kinh',
      'Da liễu',
    ],
  },
  {
    color: '#FC3F5B',
    categories: [
      'Hô hấp',
      'Tiêu hóa',
      'Tim mạch',
      'Nội tiết',
      'Sức khỏe sinh sản',
      'Thận - Tiết niệu',
    ],
  },
  {
    color: '#00D08F',
    categories: [
      'Cơ xương khớp',
      'Nhiễm',
      'Miễn dịch - Dị ứng',
      'Ung thư - Ung bướu',
      'Huyết học',
    ],
  },
  {
    color: '#3263FC',
    categories: ['Covid-19', 'Sức khỏe tinh thần', 'Sống khỏe chủ động'],
  },
  {
    color: '#6016D8',
    categories: ['Thuốc'],
  },
]
