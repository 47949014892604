import { useState } from 'react'
import { SidebarType } from '../Dropdown'

export function useDropdown(defaultState: SidebarType) {
  const [dropdown, setDropdown] = useState<SidebarType>(defaultState)

  function onToggle(dropdownType: SidebarType) {
    if (dropdownType === dropdown) return setDropdown(null)
    return setDropdown(dropdownType)
  }
  function onClose() {
    setDropdown(null)
  }
  return [dropdown, onToggle, onClose] as const
}
