import { useEffect, useState, RefObject } from 'react'

export function useHeroIntersection(
  isHome: boolean,
  ref: RefObject<HTMLElement> | ((instance: HTMLDivElement | null) => void)
) {
  const [isIntersectingWithHero, setIsIntersectingWithHero] = useState<boolean>(
    true
  )
  const [observer, setObserver] = useState<IntersectionObserver | null>(null)

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  }

  function callback(entries: any) {
    if (entries) {
      setIsIntersectingWithHero(entries[0].isIntersecting)
    }
  }

  function checkIntersectionWithHero() {
    if (typeof IntersectionObserver !== 'function') return null
    const observer = new IntersectionObserver(callback, options)
    const heroSectionEl = document.querySelector('#hero-section')
    if (heroSectionEl) {
      observer.observe(heroSectionEl)
    }
    if (observer) {
      setObserver(observer)
    }
  }

  useEffect(() => {
    if (isHome) {
      checkIntersectionWithHero()
    }
    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [ref, isHome])
  return isIntersectingWithHero
}
