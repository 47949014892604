import { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import classNames from 'classnames'
import { useDropdown } from '@/components/shared/Navbar/hooks/useDropdown'
import { useHeroIntersection } from '@/components/shared/Navbar/hooks/useHeroIntersection'

import { Chevron } from '@vhl/icons'
import Dropdown from '@/components/shared/Navbar/Dropdown'
import SearchBox from '@/components/shared/SearchBox'
import { PAGES_SLUG } from '@/constants/pages'

const DesktopFixedNavbar = () => {
  const location = useLocation()
  const isHome: boolean = location.pathname === '/'
  const [dropdownType, toggleDropdown, closeDropdown] = useDropdown(null)
  const ref = useRef<HTMLDivElement>(null)

  const isIntersectingWithHero = useHeroIntersection(isHome, ref)
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false)
  const navbarHeadingClasses = (type: string) =>
    classNames(
      'font-bold transition text-button font-heading group-hover:text-blue-500 duration-300',
      {
        'text-blue-500': dropdownType === type,
      }
    )
  useEffect(() => {
    closeDropdown()
    if (isHome) {
      setIsNavbarOpen(!isIntersectingWithHero)
    } else {
      setIsNavbarOpen(true)
    }
  }, [isIntersectingWithHero])

  useEffect(() => {
    if (isNavbarOpen) {
      document.body.classList.add('overflow-x-hidden')
    } else {
      document.body.classList.remove('overflow-x-hidden')
    }
  }, [isNavbarOpen])

  useEffect(() => {
    closeDropdown()
  }, [location.pathname])

  return (
    <>
      <AnimatePresence>
        {isNavbarOpen && (
          <motion.div
            className="sticky top-0 z-50 hidden desktop:block"
            initial={{ top: -100 }}
            animate={{ top: 0 }}
            exit={{ top: -100, transition: { duration: 0.05 } }}
            ref={ref}
          >
            <div className="flex justify-between items-center px-[118px] py-3 border-[1px] bg-white bg-opacity-75 backdrop-filter backdrop-blur-lg space-x-2">
              <div className="flex items-center justify-between w-full space-x-8">
                <div className="max-w-[200px]" onClick={() => navigate('/')}>
                  <StaticImage
                    src="../../../../images/logo/navbar.png"
                    alt="Vietnam Health Literacy Image"
                    className="cursor-pointer"
                    quality={100}
                    width={668}
                    height={232}
                    formats={['avif', 'webp', 'png']}
                    placeholder="blurred"
                  />
                </div>
                <SearchBox
                  defaultValue=""
                  className="py-2 pl-[30px] pr-[32px] rounded-full h-[35px] lg:max-w-[200px] 2xl:max-w-[300px] text-lg"
                />
                <div
                  className="flex space-x-1 p-[10px] group cursor-pointer healthstatus-navbar"
                  onClick={() => toggleDropdown('healthstatus')}
                >
                  <h5 className={navbarHeadingClasses('healthstatus')}>
                    Tình trạng sức khỏe
                  </h5>
                  <span className="relative w-fit healthstatus-navbar">
                    <Chevron
                      className={`absolute transform transition duration-300 top-1 ${dropdownType ===
                        'healthstatus' && '-rotate-180'}`}
                      stroke={
                        dropdownType === 'healthstatus' ? '#3163FC' : '#1A1A1A'
                      }
                    />
                  </span>
                </div>
                <div
                  className="flex space-x-1 p-[10px] group cursor-pointer topic-navbar"
                  onClick={() => toggleDropdown('topic')}
                >
                  <h5 className={navbarHeadingClasses('topic')}>Chuyên mục</h5>
                  <span className="relative w-fit topic-navbar">
                    <Chevron
                      className={`absolute transform transition duration-300 top-1 ${dropdownType ===
                        'topic' && '-rotate-180'}`}
                      stroke={dropdownType === 'topic' ? '#3163FC' : '#1A1A1A'}
                    />
                  </span>
                </div>
                <div className="flex space-x-[3px] p-[10px]">
                  <h5 className="font-bold transition duration-300 text-button font-heading hover:text-blue-500">
                    <Link to={PAGES_SLUG.about}>Về chúng tôi</Link>
                  </h5>
                </div>
              </div>
            </div>
            <AnimatePresence exitBeforeEnter>
              {dropdownType && (
                <motion.div
                  key={dropdownType}
                  className="absolute w-full"
                  initial={{ opacity: 0, y: -5 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -5 }}
                >
                  <Dropdown
                    type={dropdownType}
                    onOutsideClick={e => {
                      if (e) {
                        const el = e.target as HTMLElement
                        const className = el.classList
                        const parentClassName = el.parentElement?.classList
                        const dropdownClass = `${dropdownType}-navbar`
                        if (
                          className.contains(dropdownClass) ||
                          parentClassName?.contains(dropdownClass)
                        )
                          return
                        closeDropdown()
                      } else {
                        closeDropdown()
                      }
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default DesktopFixedNavbar
