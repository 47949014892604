export function splitArrayToChunks<T>(array: Array<T>, parts: number): T[][] {
  return array.reduce((arr: any, item: T, idx: number) => {
    return idx % parts === 0
      ? [...arr, [item]]
      : [...arr.slice(0, -1), [...arr.slice(-1)[0], item]]
  }, [])
}

export function slugify(str: string, separator?: string) {
  const slug = str
    .toLowerCase()
    .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
    .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
    .replace(/ì|í|ị|ỉ|ĩ/g, 'i')
    .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
    .replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
    .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
    .replace(/đ/g, 'd')
    .replace(/\s+/g, '-')
    .replace(/[^A-Za-z0-9_-]/g, '')
    .replace(/-+/g, '-')
  if (separator) {
    return slug.replace(/-/g, separator)
  }
  return slug
}

export function truncate(str: string, length: number) {
  if (str.length > length) {
    return str.substring(0, length) + '...'
  }
  return str
}

export function stripHTML(html: string) {
  return html.replace(/<(?:.|\n)*?>/gm, '')
}

export function scrollTo(id: string, offset?: number) {
  if (document) {
    const el = document.getElementById(id)
    if (el) {
      const headerOffset = offset || 0
      const elPosition = el.getBoundingClientRect().top
      const offsetPosition = elPosition + window.scrollY - headerOffset
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }
}

export function copyCurrentWindowLocation() {
  const url = window.location.href
  const textArea = document.createElement('textarea')
  textArea.value = url
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('copy')
  textArea.remove()
}

export function getCategoryTypeUrl(
  categoryType: 'series' | 'topic' | 'health-status'
) {
  switch (categoryType) {
    case 'series':
      return 'series'
    case 'topic':
      return 'chu-de'
    default:
      return 'tinh-trang-suc-khoe'
  }
}
