import { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
import { Search } from '@vhl/icons'
import classNames from 'classnames'

interface SearchBoxProps {
  parentClass?: string
  className?: string
  type?: 'full' | 'transparent'
  focus?: boolean
  defaultValue: string | null
}

const SearchBox = ({
  parentClass,
  className,
  type = 'full',
  defaultValue,
  focus = false,
}: SearchBoxProps) => {
  if (typeof window === 'undefined') return null
  const inputClass = classNames(
    {
      'py-2 pl-[30px] pr-8 rounded-full h-[35px] max-w-[200px] text-lg':
        type === 'full' && !className,
    },
    {
      'bg-white border-t-0 border-l-0 border-r-0 border-b-[1px] border-gray-500 py-1 font-sans pr-[25px]':
        type === 'transparent' && !className,
    },
    { [className || '']: className }
  )
  const [searchText, setSearchText] = useState<string | null>(defaultValue)
  const searchParams = new URLSearchParams(location.search)
  const ref = useRef<HTMLInputElement>(null)

  function search() {
    if (!searchText || searchText.length <= 0) return
    navigate(`/search?q=${searchText}`)
  }

  useEffect(() => {
    if (focus) ref.current?.focus()
  }, [])
  useEffect(() => {
    setSearchText(searchParams.get('q'))
  }, [location.search])
  return (
    <div className={`relative ${parentClass}`}>
      {type === 'full' ? (
        <>
          <input
            type="text"
            className={inputClass}
            placeholder="Tìm kiếm..."
            value={(searchText as string) || ''}
            onChange={e => setSearchText(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && search()}
            name="search-box"
            ref={ref}
          />
          <span
            className="absolute right-[15px] top-[8px] cursor-pointer"
            onClick={search}
          >
            <Search />
          </span>
        </>
      ) : (
        <>
          <input
            className={inputClass}
            placeholder="Tìm kiếm..."
            value={(searchText as string) || ''}
            onChange={e => setSearchText(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && search()}
            name="search-box"
            ref={ref}
          />
          <span className="absolute top-[2px] right-0" onClick={search}>
            <Search
              height="24px"
              width="24px"
              strokeWidth={0.8}
              stroke="#808080"
            />
          </span>
        </>
      )}
    </div>
  )
}

export default SearchBox
