import type { SidebarType } from '@/types/components'

import { useRef } from 'react'
import { useClickAway } from 'react-use'
import HealthStatusBar from './HealthStatusBar'
import TopicBar from './TopicBar'

interface DropdownProps {
  type: SidebarType
  onOutsideClick: (e?: Event) => void
}

const Dropdown = ({ type, onOutsideClick }: DropdownProps) => {
  const ref = useRef(null)
  useClickAway(ref, e => onOutsideClick(e))
  return (
    <div className="relative w-full h-full" ref={ref}>
      {type === 'healthstatus' && (
        <HealthStatusBar
          split={6}
          className="flex flex-col px-[122px] py-8"
          contentClassName="flex flex-row justify-between py-12"
        >
          <HealthStatusBar.Header className="flex flex-col space-y-[18px]" />
        </HealthStatusBar>
      )}
      {type === 'topic' && (
        <TopicBar className="px-[122px] py-8">
          <div className="flex flex-row justify-between pb-8">
            <TopicBar.MainTopic />
            <TopicBar.Series seriesSplit={5} />
            <TopicBar.OtherTopics />
          </div>
        </TopicBar>
      )}
    </div>
  )
}

export default Dropdown
